<template>
    <div class="wrapper-home-page">
        <img
            class="logo-img"
            :src="require('@/assets/images/main/home-page/Capy_sample.png')"
            data-aos="fade-down"
        />

        <div class="block-info" data-aos="zoom-in">
            <h3 class="title-text">Capy is coming soon</h3>
            <p class="desc-text">
                Hang on tight, we will be with you as soon as possible!
            </p>
            <div class="list-menu">
                <div class="list-menu__item hovered">
                    <p>
                        <a href="https://www.55mins.com/contact" target="_blank">Contact</a>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <router-link to="/privacy">Privacy</router-link>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <router-link to="/terms">Terms</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper-home-page {
    a {
        color: unset;
        &:hover {
            color: unset;
            text-decoration: none;
            opacity: unset;
        }
    }
    position: relative;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100vw auto;
    background-position: bottom;
    background-image: url('~@/assets/images/background/home.png'),
        linear-gradient(
            21.18deg,
            #fff2ea 38.86%,
            #f9f3ec 60.67%,
            #e9f8f3 89.42%,
            #cdfffe 121.95%,
            #ccffff 123.31%
        );

    .logo-img {
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -5%);
    }

    .block-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        max-width: 550px;
        .title-text {
            font-weight: 500;
            font-size: 48px;
            line-height: 56px;
            text-align: center;
            color: #212121;
        }
        .desc-text {
            margin-top: 1.5rem;
            font-family: Futura PT;
            font-size: 24px;
            line-height: 31px;
            text-align: center;
            color: #424242;
        }
        .list-menu {
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            &__item {
                @include hovered;
                &:not(:last-child) {
                    border-right: 1px solid rgba(0, 0, 0, 0.2);
                }
                p {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    color: #424242;
                    font-family: Futura PT;
                }
            }
        }
    }
}

@include tablet {
    .wrapper-home-page {
        background-image: url('~@/assets/images/background/home-tablet.png'),
            linear-gradient(
                21.18deg,
                #fff2ea 38.86%,
                #f9f3ec 60.67%,
                #e9f8f3 89.42%,
                #cdfffe 121.95%,
                #ccffff 123.31%
            );
    }
}

@include mobile {
    .wrapper-home-page {
        background-image: url('~@/assets/images/background/home-mobile.png'),
            linear-gradient(
                21.18deg,
                #fff2ea 38.86%,
                #f9f3ec 60.67%,
                #e9f8f3 89.42%,
                #cdfffe 121.95%,
                #ccffff 123.31%
            );
        .block-info {
            max-width: 80vw;
            top: 45%;
            transform: translate(-50%, -45%);
            .title-text {
                font-family: Rubik;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
            }
            .desc-text {
                font-family: Futura PT;
                font-size: 16px;
                line-height: 21px;
            }
            .list-menu {
                &__item {
                    p {
                        font-family: Futura PT;
                        font-size: 16px;
                        line-height: 24px;
                        color: #424242;
                    }
                }
            }
        }
    }
}
</style>
